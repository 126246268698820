import React, { SVGProps } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import logo from './ozoneLogo.png';

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    [theme.breakpoints.down('sm')]: {
      height: 40,
    },
  },
}));

export default function TwilioLogo(props: SVGProps<SVGSVGElement>) {
  const classes = useStyles();
  return <img src={logo} className={classes.logo} alt="ozone logo" />;
}
